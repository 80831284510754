import React from "react"
import { useStaticQuery, graphql } from 'gatsby';
import { FaqDetail } from "../../../components/faq/detail"

const breadcrumbsData: { text: string; path: string }[] = [
  { text: `FAQ`, path: `question` },
  { text: `Web制作に関するFAQ`, path: `question/web` },
]

const FaqTitle: string = "Web制作に関するFAQ";

export default function FaqGraphics({ data }) {
  const { site: { siteMetadata: { faqDetail } } } = data;
  const [ faqDetails ] = faqDetail;

  return (
    <FaqDetail
      breadcrumbsData={breadcrumbsData}
      faqDetails={faqDetails.web}
      faqTitle={FaqTitle}
    />
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        faqDetail {
          web {
            details {
              answer
              question
            }
            path
          }
        }
      }
    }
  }
`